var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"pt-5",attrs:{"fluid":""}},[_c('base-material-card',{staticClass:"px-5 py-4 ml-0",attrs:{"color":"primary","icon":"mdi-database-cog","inline":""},scopedSlots:_vm._u([{key:"after-heading",fn:function(){return [_c('div',{staticClass:"display-2 font-weight-light"},[_vm._v(" Versionamento de Bases ")])]},proxy:true}])},[_c('v-row',{staticClass:"mt-5"},[_c('v-col',{attrs:{"cols":"12","sm":"4"}},[_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){return _vm.$router.push('/administracao/versionamento-bases/versoes-ativas')}}},[_c('v-icon',{staticClass:"mr-2"},[_vm._v("mdi-table-of-contents")]),_vm._v(" Definir versões ativas ")],1)],1)],1),_c('v-row',{staticClass:"mt-2 mb-1",attrs:{"justify":"space-between"}},[_c('v-col',{attrs:{"cols":"12","sm":"4"}},[_c('v-select',{staticClass:"my-0",attrs:{"items":_vm.companies,"clearable":"","hide-details":"","item-text":"name","item-value":"id","label":"Empresa"},model:{value:(_vm.selectedCompany),callback:function ($$v) {_vm.selectedCompany=$$v},expression:"selectedCompany"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"4"}},[_c('v-text-field',{staticClass:"my-0",attrs:{"append-icon":"mdi-magnify","label":"Versão","clearable":"","hide-details":"","single-line":""},model:{value:(_vm.searchByName),callback:function ($$v) {_vm.searchByName=$$v},expression:"searchByName"}})],1)],1),_c('v-data-table',{attrs:{"dense":"","headers":_vm.headers,"items":_vm.filteredItems,"mobile-breakpoint":800,"loading":_vm.loadingTable,"hide-default-header":_vm.loadingTable,"hide-default-footer":_vm.loadingTable,"sort-by":[],"sort-desc":true,"no-data-text":"Nenhum versionamento encontrado"},scopedSlots:_vm._u([{key:"item.id",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("parseNumberToIntegerBR")(item.id))+" ")]}},{key:"item.version",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.parseMonth(item.data_month))+" v"+_vm._s(item.version)+" ")]}},{key:"item.user.name",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.user ? item.user.name : "Sistema")+" ")]}},{key:"item.created_at",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("parseToDateTimeBR")(item.created_at))+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":"","disabled":item.active_version},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"disabled":item.active_version,"color":"gray","fab":"","icon":"","x-small":""},on:{"click":function($event){return _vm.activateVersion()}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-check-bold ")])],1)]}}],null,true)},[_c('span',[_vm._v("Definir como versão ativa")])])]}}],null,true)})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }