<template>
  <v-container fluid class="pt-5">
    <base-material-card
      color="primary"
      icon="mdi-database-cog"
      inline
      class="px-5 py-4 ml-0"
    >
      <template v-slot:after-heading>
        <div class="display-2 font-weight-light">
          Versionamento de Bases
        </div>
      </template>

       <v-row class="mt-5">
        <v-col cols="12" sm="4">
          <v-btn 
            color="primary"
            @click="$router.push('/administracao/versionamento-bases/versoes-ativas')"
          >
            <v-icon class="mr-2">mdi-table-of-contents</v-icon>
            Definir versões ativas
          </v-btn>
        </v-col>
       </v-row>

      <v-row class="mt-2 mb-1" justify="space-between">
        <v-col cols="12" sm="4">
          <v-select
            :items="companies"
            clearable
            class="my-0"
            hide-details
            item-text="name"
            item-value="id"
            v-model="selectedCompany"
            label="Empresa"
          />
        </v-col>
        <v-col cols="12" sm="4">
          <v-text-field
            class="my-0"
            v-model="searchByName"
            append-icon="mdi-magnify"
            label="Versão"
            clearable
            hide-details
            single-line
          />
        </v-col>
      </v-row>
      
      <v-data-table
        dense
        :headers="headers"
        :items="filteredItems"
        :mobile-breakpoint="800"
        :loading="loadingTable"
        :hide-default-header="loadingTable"
        :hide-default-footer="loadingTable"
        :sort-by="[]"
        :sort-desc="true"
        no-data-text="Nenhum versionamento encontrado"
      >
        <template v-slot:[`item.id`]="{ item }">
          {{ item.id | parseNumberToIntegerBR }}
        </template>
        <template v-slot:[`item.version`]="{ item }">
          {{ parseMonth(item.data_month) }} v{{ item.version }}
        </template>
        <template v-slot:[`item.user.name`]="{ item }">
          {{ item.user ? item.user.name : "Sistema" }}
        </template>
        <template v-slot:[`item.created_at`]="{ item }">
          {{ item.created_at | parseToDateTimeBR }}
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-tooltip bottom :disabled="item.active_version">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                :disabled="item.active_version"
                v-bind="attrs"
                v-on="on"
                color="gray"
                fab
                icon
                x-small
                @click="activateVersion()"
              >
                <v-icon small>
                  mdi-check-bold
                </v-icon>
              </v-btn>
            </template>
            <span>Definir como versão ativa</span>
          </v-tooltip>
        </template>
      </v-data-table>
    </base-material-card>
  </v-container>
</template>

<script>

import dayjs from 'dayjs';

import IndgerVersionsService from '@/services/IndgerVersionsService';
import CompanyService from '@/services/CompanyService';

export default {
  name: "VersionamentoBasesIndex",
  components: {},
  data: () => ({
    items: [],
    headers: [
      {
        text: 'Versão',
        value: 'version'
      },
      {
        text: 'Empresa',
        value: 'company.name'
      },
      {
        text: 'Criado por',
        value: 'user.name'
      },
      {
        text: 'Criado em',
        value: 'created_at'
      },
    ],
    searchByName: "",
    companies: [],
    selectedCompany: null,
    loadingTable: false,
    // dialogDetalhesStatusData: {},
    // dialogDetalhesStatus: false,
    // loadingActions: false
  }),
  computed: {
    filteredItems() {
      let items = this.items;

      if (this.selectedCompany) {
        items = items.filter(item => item.company.id == this.selectedCompany);
      }
      if (this.searchByName) {
        items = items.filter(item => `${this.parseMonth(item.data_month)} v${item.version}`.toLowerCase().includes(this.searchByName.toLowerCase()));
      }

      return items;
    },
  },
  mounted() {
    this.getData();
  },
  methods: {
    getData() {
      return Promise.all([
        this.getAllVersions(),
        this.getCompanies(),
      ])
        .catch(err => {
          console.error(err);
        })
        .finally(() => this.loadingTable = false);
    },
    getAllVersions() {
      this.loadingTable = true;

      IndgerVersionsService.getAllVersions()
        .then(res => {
          this.items = res;
        })
        .catch(err => {
          throw err;
        });
    },
    getCompanies() {
      this.loadingTable = true;

      CompanyService.getCompanies()
        .then(res => {
          this.companies = res;
        })
        .catch(err => {
          throw err;
        });
    },
    activateVersion() {
      return;
    },
    deleteVersion() {
      return;
    },
    parseMonth(date) {
      return dayjs(date).format('MM/YYYY');
    }
  }
}

</script>

<style>

</style>